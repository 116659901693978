import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {updateFilter} from 'helpers/filter-helpers';
import {getFilteredAndSortedVenues} from 'helpers/venues-helper';
import {getFilteredAndSortedRooms} from 'helpers/rooms-helper';
import {venueSortablesData} from 'data/venue-sortables-data';
import {roomSortablesData} from 'data/room-sortables-data';
import {uiTexts} from 'data/ui-texts';
import Rooms from 'components/rooms/rooms';
import Venues from 'components/venues/venues';
import Filters from 'components/ui/filters/filters';
import './rooms-or-venues.scss';

const RoomsOrVenues = ({isLoggedIn, languageId, deviceInfo, userData}) => {
	/* Page: venues or rooms */
	const [page, setPage] = useState('rooms');

	/* Filter: region & city */
	const [regionId, setRegionId] = useState('denmark');
	const [cityId, setCityId] = useState('all-cities');
	const handleSelectRegionId = (regionId) => {
		setRegionId(regionId);
		setCityId('all-cities');
	};

	/* Filter: language ids */
	const [excludedLanguageIds, setExcludedLanguageIds] = useState([]);
	const toggleLanguageId = (languageId) => {
		const newExcludedLanguageIds = updateFilter(languageId, excludedLanguageIds);
		setExcludedLanguageIds(newExcludedLanguageIds);
	};

	/* Filter: closed rooms/venues */
	const [showClosed, setShowClosed] = useState(false);

	/* Filter: future rooms/venues */
	const [showFuture, setShowFuture] = useState(false);

	/* Filter: hide completed */
	const [hideCompleted, setHideCompleted] = useState(false);

	/* Room sort options */
	const [sortBy, setSortBy] = useState('sortableOpened');
	const [sortDir, setSortDir] = useState('DESC');
	const sortRooms = (newSortBy) => {
		if (newSortBy === sortBy) return;
		const sortByData = roomSortablesData.find((s) => {return s.id === newSortBy;});
		const newSortDir = (sortByData.sortDirection ? sortByData.sortDirection : 'ASC');
		setSortBy(newSortBy);
		setSortDir(newSortDir);
	};
	const switchSortDirection = () => {
		const newSortDir = (sortDir === 'ASC' ? 'DESC' : 'ASC');
		setSortDir(newSortDir);
	};

	/* Venue sort options */
	const [sortVenuesBy, setSortVenuesBy] = useState('sortableOpened');
	const [sortVenuesDir, setSortVenuesDir] = useState('DESC');
	const sortVenues = (newSortBy) => {
		if (newSortBy === sortVenuesBy) return;
		const sortByData = venueSortablesData.find((s) => {return s.id === newSortBy;});
		const newSortDir = (sortByData.sortDirection ? sortByData.sortDirection : 'ASC');
		setSortVenuesBy(newSortBy);
		setSortVenuesDir(newSortDir);
	};
	const switchSortVenuesDirection = () => {
		const newSortDir = (sortVenuesDir === 'ASC' ? 'DESC' : 'ASC');
		setSortVenuesDir(newSortDir);
	};


	/* Get venues */
	const venues = 
		getFilteredAndSortedVenues(showClosed, showFuture, 
			languageId, regionId, cityId, sortVenuesBy, sortVenuesDir);


	/* Get rooms */
	const rooms = 
		getFilteredAndSortedRooms(showClosed, showFuture, hideCompleted,
			languageId, regionId, cityId, excludedLanguageIds, sortBy, sortDir, userData);


	/* Get page component */
	const Component = (page === 'venues' ? Venues : Rooms);
	

	return (
		<div className={'RoomsOrVenues ' + deviceInfo.orientation}>
			<div className="RoomsOrVenues-filters">
				<Filters 
					isLoggedIn={isLoggedIn}
					showClosed={showClosed}
					showFuture={showFuture}
					hideCompleted={hideCompleted}
					languageId = {languageId}
					page={page}
					regionId = {regionId}
					cityId = {cityId}
					excludedLanguages={excludedLanguageIds}
					sortBy={(page === 'rooms' ? sortBy : sortVenuesBy)}
					sortDirection={(page === 'rooms' ? sortDir : sortVenuesDir)}
					setShowClosed={setShowClosed}
					setShowFuture={setShowFuture}
					handleSelectRegionId={handleSelectRegionId}
					setCityId={setCityId}
					toggleLanguage={toggleLanguageId}
					setHideCompleted={setHideCompleted}
					handleSort={(page === 'rooms' ? sortRooms : sortVenues)}
					handleSortDirection={(page === 'rooms' ? switchSortDirection : switchSortVenuesDirection)}

				/>
			</div>
			<Link className="RoomsOrVenues-map" to={'/map'}>
				{uiTexts.viewOnMap[languageId]}
			</Link>
			<Link className="RoomsOrVenues-about" to={'/about'}></Link>
			
			<div className="RoomsOrVenues-pagesNav">
				<div 
					className={'RoomsOrVenues-pagesNavItem venues' + (page === 'venues' ? ' selected' : '')}
					onClick={() => {setPage('venues');}}
				>
					<span>{uiTexts.resultVenues[languageId]} ({venues.length})</span>
				</div>
				<div 
					className={'RoomsOrVenues-pagesNavItem rooms' + (page === 'rooms' ? ' selected' : '')}
					onClick={() => {setPage('rooms');}}
				>
					<span>{uiTexts.resultEscapeRooms[languageId]} ({rooms.length})</span>
				</div>
			</div>
			<div className="RoomsOrVenues-pages">
				<Component 
					showClosed={showClosed}
					showFuture={showFuture}
					languageId={languageId}
					deviceInfo={deviceInfo}
					venues={venues}
					rooms={rooms}
					userData={userData}
				/>
			</div>
			
		</div>
	);
};


RoomsOrVenues.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	languageId: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object
};

export default RoomsOrVenues;