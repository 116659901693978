const venueTemplate = require('../../templates').venueTemplate;
const roomTemplate = require('../../templates').roomTemplate;

const venueData = Object.assign({}, venueTemplate, {
	id: 'escapist',
	name: 'Escapist',
	website: 'http://www.escapist.dk',
	locations: [
		{
			id: 'aarhus',
			cityId: 'aarhus',
			opened: '20170601',
			closed: false,
			address: 'Klostergade 82, 8000 Aarhus',
			coordinates: {lat: '56.158547', lng: '10.202769'},
		},
		{
			id: 'tilst',
			cityId: 'tilst',
			opened: '20210716',
			closed: true,
			address: 'Langkærvej 2B, 8381 Tilst',
			coordinates: {	lng: '10.104235', lat: '56.187618'}
		}
	],
	rooms: [
		Object.assign({}, roomTemplate, {
			id: 'the-great-diamond-heist',
			name: {da: 'Det Store Diamantkup', en: 'The Great Diamond Heist'},
			languages: ['da', 'en'],
			minimumAgeNoAdult: 13,
			difficulty: 33.33,
			tagIds: ['kids', 'beginners'],
			locations: [{
				locationId: 'aarhus',
				opened: '20170601',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'uss-triton',
			name: {da: 'USS Triton', en: 'USS Triton'},
			languages: ['da', 'en'],
			teamSizeMax: 5,
			minimumAgeNoAdult: 13,
			difficulty: 80,
			locations: [{
				locationId: 'aarhus',
				opened: '20180530',
				closed: null,
				rooms: 1
			}]
		}),
		Object.assign({}, roomTemplate, {
			id: 'wanted',
			name: {da: 'Wanted', en: 'Wanted'},
			languages: ['da', 'en'],
			minimumAgeNoAdult: 13,
			locations: [{
				locationId: 'tilst',
				opened: '20210716',
				closed: true,
				rooms: 2
			}]
		}),
	]
});


export {venueData};